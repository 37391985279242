import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"


const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/channel-partners/",
    label: "CHANNEL PARTNERS",
  },
]

const PartnerChannel = () => (
  <Layout>
    <Seo title="CHANNEL PARTNERS" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg --for-it-ops-4">
      <section className="section-title-top--platform partners-chan">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center pb-2">
              VIGILANT CHANNEL PARTNERS
              </h1>
              <p className="text-center pb-2 font-size-3">Your one stop shop for for marketing materials and registering deals. </p>
            </div>
            
            <div className="col-8 mx-auto">
                <div className="row">
                <div className="col-4 mx-auto">
            <Link to="/channel-partners/register-deal" className="nav-section--cta-btn-par--fill">
            Register A Deal
              </Link>
            </div>

            <div className="col-4 mx-auto">
            <Link to="/resources" className="nav-section--cta-btn-par">
            Access Resources
              </Link>
            </div>
                </div>
            </div>

          </div>
        </div>
      </section>
    </div>


    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default PartnerChannel
